import { format } from "date-fns";

const notEditableStatusList = [
    "expired",
    "pending",
];

export const isDirectPurchaseEditable = (chgStatus) => notEditableStatusList.includes(chgStatus) === false;

export const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Spinner, FormFeedback } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Select from 'components-form/Select';
import Error404 from 'page/error/Error404';
import IcInfo from 'assets/svg/ic_information.svg';
import InputCounter from 'components-form/InputCounter';
import InputDecimal, { transformDecimal } from './components/InputDecimal';
import { useSelector } from 'react-redux';
import ModalPreview from './components/PreviewCase';
import { TextArea } from 'components-form';
import TextInput from 'components-form/textInput';
import InputCounterDebounce from 'components-form/InputCounterDebounce';
import IcImage from 'assets/svg/ic_add-image.svg'
import FilUploaderContext from 'components-form/FileUploaderContext';
import icCloseRound from 'assets/svg/ic_cancel.svg';
import { isBlankOrNullish, parseValue } from 'helper';
import { components } from "react-select";
import { useAsyncPaginate, useComponents } from 'react-select-async-paginate';
import InputDebounceNumber from 'components-form/InputDebounceNumber';
import ImagePreview from './components/ImagePreview';
import { DetailContext } from './detail';
import DetailImagePreview from './components/DetailImagePreview';
import ModalConfirmEdit, { useConfirmEdit } from './components/ModalConfirmEdit';
import { isDirectPurchaseEditable } from './constants';

export const PembelianContextForm = createContext();
const priceProps = { max: 9999999999, maxed: 10 };
const formatPrice = (price) => transformDecimal(price, priceProps);

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const isCreate = params?.id ? false : true;
    const { hasFetch } = useAction();

    const dataBreadcrumb = isCreate ?
        [
            { to: '/direct-purchase', label: 'Pembelian Langsung' },
            { to: null, label: 'Buat Pembelian Baru' }
        ]
        : [
            { to: '/direct-purchase', label: 'Pembelian Langsung' },
            { to: `/direct-purchase/${params.id}`, label: 'Detail' },
            { to: null, label: 'Edit Pembelian Langsung' }
        ];

    /* fecthing data for detail page */
    const { data, status, error, loading } = useFetch(
        `/api/back-office/direct-purchase/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )
    const [responseData, setResponseData] = useState(null);
    const [open, setopen] = useState(false);
    const [openImage, setopenImage] = useState(false);
    const [isLoadingSimpan, setIsLoadingSimpan] = useState(false);

    const editable = isCreate || loading || isDirectPurchaseEditable(data?.chgStatus);
    const { config: confirmEditConfig, confirmEdit } = useConfirmEdit();

    const onRequestEdit = (input) => confirmEdit(
        "confirm",
        {
            directPurchaseId: params?.id,
            totalContainer18: input?.jeriken18L,
            totalContainer20: input?.jeriken20L,
            purchasePrice: parseFloat(input?.price?.replaceAll(".", "")),
            purchaseDate: data?.purchaseDate // being used on Modal only (not processed by BE)
        }
    );

    const onSubmit = (input) => {
        setIsLoadingSimpan(true);
        const requestBody = {
            "supplierId": input?.supplierId,
            "branchId": input?.branch,
            "stockpointId": input?.stockpoint,
            "salespersonCode": input?.nama_salesperson,
            "totalContainer18L": input?.jeriken18L,
            "totalContainer20L": input?.jeriken20L,
            "purchasePrice": parseFloat(input?.price?.replaceAll('.', '')),
            "purchasePow": input?.storageFilePath
        };
        const action = ({ requestBody, id = null }) => Services().post('/api/back-office/direct-purchase', requestBody)
        action({ requestBody, id: params?.id })
            .then(({ data }) => {
                setIsLoadingSimpan(false);
                setopen(!open);
                setResponseData(data?.data);
            })
            .catch(e => {
                setIsLoadingSimpan(false);
                setopen(false);
                setResponseData(null);
                hasFetch({
                    type: 'ALERT_TOAST_ERROR',
                    payload: { message: e?.message }
                });
            })
    }

    const defaultValues = {
        "supplierId": data?.supplierId ?? "",
        "phone": data?.phone ?? "",
        "nama_lengkap": data?.supplierName ?? "",
        "nama_outlet": data?.outletName ?? "",
        "selfDeclaration": data?.selfDeclaration === true ? "checked" : null,
        "statusSD": data?.statusSD ?? null,
        "alamat": data?.address ?? "",
        "nama_salesperson": data?.salespersonName ?? "",
        "branch": data?.branchId ?? null,
        "stockpoint": data?.stockpointId ?? null,
        "jeriken18L": data?.totalContainer18L ?? "",
        "totalContainer18": data?.totalContainer18L ?? "",
        "jeriken20L": data?.totalContainer20L ?? "",
        "totalContainer20": data?.totalContainer20L ?? "",
        "estimasiPrice": data?.totalOilWeight ?? "",
        "price": data?.purchasePrice
            ? formatPrice(data.purchasePrice)
            : "",
        "buktiPembelian": null,
        "storageFilePath": ""
    }
    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null) || !editable) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <PembelianContextForm.Provider
            value={{
                open,
                setopen,
                data: responseData,
                openImage,
                setopenImage
            }}
        >
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Pembelian</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="penggunaForm" onSubmit={isCreate ? onSubmit : onRequestEdit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Pembelian Langsung
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="phone" sm={3}>Nomor HP Supplier</Label>
                                    <Col sm={9}>
                                        <PhoneNumber disabled={!isCreate} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="nama_lengkap" sm={3}>Nama Supplier</Label>
                                    <Col sm={9}>
                                        <SupplierName />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="nama_outlet" sm={3}>Nama Outlet</Label>
                                    <Col sm={9}>
                                        <Outlet />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="self_declaration" sm={3}>Self Declaration</Label>
                                    <Col sm={9}>
                                        <SelfDeclaration />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="verifikasi_self_declaration" sm={3}>Verifikasi Self Declaration</Label>
                                    <Col sm={9}>
                                        <VerifiedSelfDeclaration />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="alamat" sm={3}>Alamat</Label>
                                    <Col sm={9}>
                                        <TextArea disabled id="alamat" rows={4} name="alamat" type="text" placeholder="Alamat" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="nama_salesperson" sm={3}>Nama Salesperson</Label>
                                    <Col sm={9}>
                                        <SalesPerson
                                            {...(isCreate
                                                ? {}
                                                : {
                                                    isDisabled: true,
                                                    placeholder: `${data?.salespersonCode} - ${data?.salespersonName}`
                                                }
                                            )}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="stockpoint" sm={3}>Branch</Label>
                                    <Col sm={9}>
                                        <BranchField name="branch" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="stockpoint" sm={3}>Stockpoint</Label>
                                    <Col sm={9}>
                                        <StockPoint name="stockpoint" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3} htmlFor="jeriken18L">Jumlah Jeriken 18L</Label>
                                    <Col sm={3}>
                                        <JerikenInput name="jeriken18L" totalName="totalContainer18" keyToWatch="jeriken20L" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3} htmlFor="jeriken20L">Jumlah Jeriken 20L</Label>
                                    <Col sm={3}>
                                        <div className='d-flex flex-column' style={{ width: "100%", gap: 12 }}>
                                            <JerikenInput name="jeriken20L" totalName="totalContainer20" keyToWatch="jeriken18L" />
                                            <div className='d-flex' style={{ gap: 6, alignItems: 'self-start' }}>
                                                <img
                                                    src={IcInfo} alt='create' style={{ marginTop: "2px" }} height={14}
                                                />
                                                <div className='d-flex'>
                                                    <div style={{ flex: 1, color: "#70727D", fontSize: 12, fontWeight: 500 }}>Note : </div>
                                                    <div style={{ flex: 5, width: '13rem', color: "#70727D", fontSize: 12, fontWeight: 500 }}>Jumlah Jeriken tidak boleh
                                                        kosong. Minimal harus terisi di salah
                                                        satu Jeriken 18 atau 20
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="estimasi_jumlah_dalam_kg" sm={3}>Estimasi Jumlah dalam KG</Label>
                                    <Col sm={3}>
                                        <EstimationKg />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="price" sm={3}>Total Pembelian (Rp)</Label>
                                    <Col sm={3}>
                                        <InputDecimal {...priceProps} maxLength={13} id="price" validation={['required', 'minLengthNumber[4]']} name="price" type="text" placeholder="0" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="avgPerKg" sm={3}>Harga Rata-rata per KG</Label>
                                    <Col sm={3}>
                                        <PricePerKg name="avgPerKg" isCreate={isCreate} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="bukti_pembelian" sm={3}>Bukti Pembelian</Label>
                                    <Col sm={9}>
                                        {isCreate ? (
                                            <>
                                                <DragDrop />
                                                <ImagePreview />
                                            </>
                                        ) : data?.purchasePow && (
                                            <DetailContext.Provider value={{
                                                openImage,
                                                setopenImage,
                                                images: data?.purchasePow
                                            }}>
                                                <div
                                                    onClick={() => setopenImage(!openImage)}
                                                    style={{ borderRadius: 4, width: 102, cursor: 'pointer' }}
                                                >
                                                    <img src={data.purchasePow} style={{ background: 'white', padding: 3 }} width='100%' height='auto' alt={`${data?.referenceCode}`} />
                                                </div>
                                                <DetailImagePreview />
                                            </DetailContext.Provider>
                                        )}
                                        {/* <Input id="bukti_pembelian" validation={['required', 'minLength[3]', 'notSymbolNumber']} maxLength={50} name="nama_lengkap" type="text" placeholder="Ketik Nama Salesperson" autoComplete="off" /> */}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={`/direct-purchase`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <ButtonSaving isLoadingSimpan={isLoadingSimpan} />
                    </Col>
                </Row>
                <ModalPreview />
                {!isCreate && <ModalConfirmEdit config={confirmEditConfig} />}
            </Form>
        </PembelianContextForm.Provider>
    )
}

const ButtonSaving = ({ isLoadingSimpan }) => {
    const { formState: { isValid }, getFieldState } = useFormContext();
    // const { getValues } = useFormContext();
    // const statusSD = getValues()?.statusSD;
    // const selfDeclaration = getValues()?.selfDeclaration;
    const averagePriceInvalid = getFieldState("avgPerKg")?.invalid;
    const disabled = !isValid || isLoadingSimpan || averagePriceInvalid;
    return (
        <Button disabled={disabled} type="submit" color={disabled ? "secondary" : "primary"} >
            {isLoadingSimpan &&
                <span className='mr-2'>
                    <Spinner size="sm" />
                </span>}
            Simpan
        </Button>
    )
}

const PhoneNumber = (props) => {
    const { hasFetch } = useAction();
    const { formState: { errors }, setValue, reset, setError } = useFormContext();
    const callback = ({ value }) => {
        if (value.length > 9) {
            Services().get('/api/back-office/direct-purchase/supplier', {
                phone: value
            }).then(supplierCredit => {
                const { data } = supplierCredit.data
                setValue('supplierId', data?.supplierId)
                setValue('nama_lengkap', data?.supplierName);
                setValue('nama_outlet', data?.outletName);
                setValue('alamat', data?.address);
                setValue('branch', data?.branchId);
                setValue('stockpoint', data?.stockpointId);
                setValue('selfDeclaration', data?.selfDeclaration);
                setValue('statusSD', data?.statusSD);
            }).catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Not Found` } })
                setError('phone', {
                    type: 'manual',
                    message: e?.message ?? `Not Found`
                })
                reset({
                    phone: value
                });
            });
        }
    }
    return (
        <InputDebounceNumber fetch={errors?.phone ? () => null : (val) => callback(val)} id="phone" validation={['required']} maxLength={50} name="phone" type="text" placeholder="Ketik Nomor HP Supplier" autoComplete="off" {...props} />
    )
}

const MIN_SEARCH_LENGTH = 3;

const SalesPerson = (props) => {
    const DEBOUNCE_TIMEOUT = 600;
    const MIN_TIME_TO_RETRY = 5_000;
    const PER_PAGE_COUNT = 20;
    const NO_OPTIONS_MESSAGE = "Salesperson belum terdaftar, silahkan daftar terlebih dahulu";

    const { hasFetch } = useAction();

    const loadOptions = async (search, _loadedOptions, additional) => {
        if (search.length > 0 && search.length < MIN_SEARCH_LENGTH) {
            hasMoreRef.current = false;
            return emptyOptions({ additional });
        }

        try {
            const { data: { data } } = await Services().get("/api/back-office/direct-purchase/salesperson", {
                search,
                page: additional.page,
                size: PER_PAGE_COUNT,
            });

            lastFailedRef.current = null;
            hasMoreRef.current = !data.last;

            return {
                hasMore: !data.last,
                options: data.content.map((salesItem) => ({
                    label: `${salesItem.code} - ${salesItem.name}`,
                    value: salesItem.code,
                })),
                additional: {
                    page: additional.page + 1,
                },
            };
        } catch (error) {
            if (lastFailedRef.current === null) {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error.message ?? "Gagal memuat daftar salesperson" } });
                lastFailedRef.current = +new Date();
            }

            return emptyOptions({ additional });
        }
    };

    const emptyOptions = ({ additional }) => ({
        hasMore: hasMoreRef.current ?? false,
        options: [],
        additional,
    });

    const lastFailedRef = useRef(null);
    const hasMoreRef = useRef(null);
    const [input, setInput] = useState('');

    const debounceTimeout = input.length < MIN_SEARCH_LENGTH
        ? 0
        : DEBOUNCE_TIMEOUT;

    const components = useComponents();
    const asyncPaginateProps = useAsyncPaginate({
        loadOptions,
        debounceTimeout,
        defaultOptions: true,
        additional: {
            page: 0,
        },
        onInputChange: (value) => setInput(value),
        shouldLoadMore: (scrollHeight, clientHeight, scrollTop) => {
            const bottomBorder = scrollHeight - clientHeight;
            const isScrolled = scrollTop >= bottomBorder;

            if (lastFailedRef.current === null) {
                return isScrolled;
            } else {
                const sinceLastFailed = (+new Date()) - lastFailedRef.current;

                /**
                 * Retry loading more options only after: a certain amount of time, or
                 * user manually move scroll bar up and down.
                 */
                if (sinceLastFailed > MIN_TIME_TO_RETRY || scrollTop < bottomBorder) {
                    lastFailedRef.current = null;
                    return isScrolled;
                } else {
                    return false;
                }
            }
        },
    });

    return (
        <Select
            {...asyncPaginateProps}
            components={input.length > 0 && input.length < MIN_SEARCH_LENGTH
                ? { ...components, MenuList }
                : components
            }
            noOptionsMessage={() => NO_OPTIONS_MESSAGE}
            id="nama_salesperson"
            name="nama_salesperson"
            validation={['required']}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Pilih / Ketik Nama Salesperson"
            {...props}
        />
    )
}

const MenuList = (props) => (
    <components.MenuList {...props}>
        <div style={props.getStyles("noOptionsMessage", props)}>
            Minimal {MIN_SEARCH_LENGTH} karakter
        </div>
    </components.MenuList>
);

const SupplierName = () => {
    return (
        <Input disabled id="nama_lengkap" maxLength={50} name="nama_lengkap" type="text" placeholder="Nama Supplier" autoComplete="off" />
    )
}

const Outlet = () => {
    return (
        <Input disabled id="nama_outlet" maxLength={50} name="nama_outlet" type="text" placeholder="Nama Outlet" autoComplete="off" />
    )
}

const SelfDeclaration = () => {
    const { watch, setValue } = useFormContext();
    const selfDeclaration = watch('selfDeclaration');
    const [color, setcolor] = useState('');
    const [bg, setbg] = useState('');
    useEffect(() => {
        if (selfDeclaration === null) {
            setValue('selfDeclaration', '-');
            setcolor('text-normal');
            setbg('border border-success-subtle')
        }
        else if (selfDeclaration === 'checked') {
            setValue('selfDeclaration', 'Ada');
            setcolor('text-success');
            setbg('bg-success');
        }
        else if (selfDeclaration === 'unchecked') {
            setValue('selfDeclaration', 'Tidak Ada');
            setcolor('text-danger');
            setbg('bg-danger');
        }
    }, [selfDeclaration, setValue, setcolor, setbg]);
    return (
        <div className='d-flex align-items-center' style={{ gap: 6 }}>
            <div className={`rounded-circle ${bg}`} style={{ height: 16, width: 16 }} />
            <TextInput className={color} name="selfDeclaration" />
        </div>
    )
}

const VerifiedSelfDeclaration = () => {
    const { watch, setValue } = useFormContext();
    const statusSD = watch('statusSD');
    const [color, setcolor] = useState('');
    const [bg, setbg] = useState('');
    useEffect(() => {
        if (statusSD === null) {
            setValue('statusSD', '-');
            setcolor('text-normal');
            setbg('border border-success-subtle')
        }
        else if (statusSD === 'verified') {
            setValue('statusSD', 'Terverifikasi');
            setcolor('text-success');
            setbg('bg-success');
        }
        else if (statusSD === 'unverified') {
            setValue('statusSD', 'Belum Terverifikasi');
            setcolor('text-warning');
            setbg('bg-warning');
        }
        else if (statusSD === 'expired') {
            setValue('statusSD', 'Kedaluwarsa');
            setcolor('text-purple');
            setbg('bg-purple');
        }
        else if (statusSD === 'rejected') {
            setValue('statusSD', 'Ditolak');
            setcolor('text-danger');
            setbg('bg-danger');
        }
    }, [statusSD, setValue, color]);
    return (
        <div className='d-flex align-items-center' style={{ gap: 6 }}>
            <div className={`rounded-circle ${bg}`} style={{ height: 16, width: 16 }} />
            <TextInput className={color} name="statusSD" />
        </div>
    )
}

const EstimationKg = () => {
    const { watch, setValue } = useFormContext();
    const totalContainer18 = watch('totalContainer18');
    const totalContainer20 = watch('totalContainer20');
    useEffect(() => {
        Services().post('/api/back-office/direct-purchase/get-oil-weight', {
            totalContainer18L: totalContainer18,
            totalContainer20L: totalContainer20,
        }).then(jerikenTotal => {
            const { data } = jerikenTotal?.data;
            setValue('estimasiPrice', data);
        })
    }, [totalContainer18, totalContainer20, setValue])

    return (
        <InputCounter
            nocounter
            id="estimasiPrice"
            disabled
            maxLength={30}
            name="estimasiPrice"
            type="text"
            placeholder="0"
            autoComplete="off"
        />)
}

const PricePerKg = ({ name, isCreate }) => {
    const { clearErrors, getFieldState, setError, setValue, watch } = useFormContext();

    const stockpointId = watch("stockpoint");
    const rawWeight = watch("estimasiPrice");
    const rawPrice = watch("price");
    const rawAvgPrice = watch(name);
    const isPriceValid = getFieldState("price")?.invalid === false;
    const totalWeight = parseValue(rawWeight, Number, 0);
    const totalPrice = parsePrice(rawPrice);
    const avgPrice = parsePrice(rawAvgPrice);

    /* Call service for standard price information */
    const { data } = useFetch(
        `/api/back-office/direct-purchase/harga-rata/${stockpointId}`,
        {},
        useCallback(({ data }) => ({ data }), []),
        { showAlertOnError: true, onMount: Boolean(stockpointId) }
    );

    const [priceStatus, range] = useMemo(
        () => {
            const shouldCheck = [
                isPriceValid,
                avgPrice,
                data?.hargaAtas,
                data?.hargaBawah,
                data?.threshold,
            ].every(Boolean);

            if (shouldCheck) {
                const percentage = data.threshold * 1.0 / 100;
                const floor = data.hargaBawah - (data.hargaBawah * percentage);
                const ceil = data.hargaAtas + (data.hargaAtas * percentage);
                const range = [`Rp${floor}`, `Rp${ceil}`]

                if (avgPrice < floor) {
                    return ["below", range];
                } else if (avgPrice > ceil) {
                    return ["above", range];
                } else {
                    return ["normal"];
                }
            } else {
                return ["normal"];
            }
        },
        [isPriceValid, avgPrice, data]
    );

    const shouldWarn = priceStatus === "above";

    useEffect(() => {
        if (priceStatus === "below") {
            setError(name, {
                type: "validate",
                message: `Harga rata-rata dibawah harga standar. Harga standar saat ini ${range.join(" - ")}`,
            });
        } else {
            clearErrors(name);
        }
    }, [clearErrors, name, priceStatus, range, setError]);

    useEffect(() => {
        if (totalWeight > 0 && isPriceValid) {
            setValue(name, formatPrice(parseInt(totalPrice / totalWeight)));
        } else {
            setValue(name, "0");
        }
    }, [setValue, isPriceValid, name, totalWeight, totalPrice]);

    return (
        <>
            <Input
                disabled
                id={name}
                name={name}
                type="text"
                placeholder="0"
                autoComplete="off"
                invalidClass="text-nowrap"
                style={{ borderColor: shouldWarn ? "#FF7D1D" : undefined }}
                maxLength={10}
            />
            {shouldWarn && (
                <FormFeedback className="d-block text-nowrap" style={{ color: "#FF7D1D" }}>
                    Harga rata-rata di{priceStatus === "above" ? "atas" : "bawah"} harga standar. Harga standar saat ini {range.join(" - ")}
                </FormFeedback>
            )}
        </>
    );
};

const parsePrice = (value) => parseValue(
    value,
    (val) => Number(String(val).replaceAll(".", "")),
    0
);

function DragDrop() {
    const { getFieldState, watch, setValue } = useFormContext();
    const { openImage, setopenImage } = useContext(PembelianContextForm);
    const files = watch('buktiPembelian');
    const { invalid } = getFieldState("buktiPembelian");
    const [img, setimg] = useState(null);
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    useEffect(() => {
        if (files) {
            toBase64(files).then(raw => {
                setimg(raw);
                let formDataImages = new FormData();
                formDataImages.append("file", files);
                Services().post('api/image/purchase-pow', formDataImages).then(rawProvenImage => {
                    setValue('storageFilePath', rawProvenImage?.data?.data?.storageFilePath)
                })
            })
        }
    }, [files, setValue]);

    return (
        <div>
            {!img && <FilUploaderContext
                types={["JPEG", "PNG", "JPG"]}
                name="buktiPembelian"
                validation={['required']}
                maxSize={3}
                children={<div className={`d-flex flex-column justify-content-center align-items-center border image-upload ${invalid ? "is-invalid border-danger" : ""}`} style={{ borderRadius: 4, width: 102, height: 102, gap: 2, cursor: 'pointer' }}>
                    <img className='ml-2' src={IcImage} height={24} width={24} alt="file uploader" />
                    <span className='text-sm' style={{ color: '#9C9DA6' }}>Upload</span>
                </div>}
            />}
            {img && <div className='d-flex flex-column position-relative justify-content-center align-items-center border border-secondary-subtle' style={{ borderRadius: 4, width: 102, overflow: "hidden", gap: 2, cursor: 'pointer' }}>
                <div onClick={() => setopenImage(!openImage)}>
                    <img style={{ maxWidth: 102 }} src={img} alt="file uploader" />
                </div>
                <div onClick={() => {
                    setimg(null);
                    setValue('buktiPembelian', null);
                }} className='position-absolute' style={{ top: 2, right: 2 }}>
                    <span>
                        <img src={icCloseRound} style={{ background: 'white', padding: 3 }} className='rounded-circle border border-secondary-subtle' width={22} height={22} alt='ic_cancel' />
                    </span>
                </div>
            </div>}
            {img && <span className='text-md text-primary' >{files?.name}</span>}
        </div>
    );
}

const JerikenInput = ({ name, totalName, keyToWatch }) => {
    const { setValue, trigger, watch } = useFormContext();
    const value = watch(name);
    const valueToWatch = watch(keyToWatch);

    const isZero = (value) => String(value) === "0";

    const validation = useMemo(
        () => {
            switch (true) {
                case isBlankOrNullish(value) && (isBlankOrNullish(valueToWatch) || isZero(valueToWatch)):
                    return ["required"];
                case isZero(value) && isZero(valueToWatch):
                    return ["minValue[1]"];
                default:
                    return ["maxLength[5]"];
            }
        },
        [value, valueToWatch]
    );

    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        } else {
            trigger(name);
            trigger(keyToWatch);
        }
    }, [trigger, name, value, keyToWatch, valueToWatch]);

    const parseJerikenInput = (input, fallbackValue = '') => {
        const parsedValue = parseInt(input);
        return isNaN(parsedValue) ? fallbackValue : parsedValue;
    };

    const fetch = ({ value }) => {
        setValue(totalName, parseJerikenInput(value, 0));
    };

    return (
        <InputCounterDebounce
            nocounter
            id={name}
            name={name}
            fetch={fetch}
            validation={validation}
            maxLength={5}
            type="text"
            placeholder="0"
            autoComplete="off"
            transform={{
                input: parseJerikenInput,
                output: (e) => parseJerikenInput(e.target.value)
            }}
        />
    )
}

const BranchField = ({ name }) => {
    const { setValue } = useFormContext();
    const { branchId, stockpointId } = useSelector(state => state?.homesidemenu?.profile);

    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    useEffect(() => {
        if (branchId) {
            setValue('branch', branchId)
        }
    }, [branchId, setValue]);
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            isDisabled={true ?? !!stockpointId}
            classNamePrefix="select"
            options={data?.map(obj => ({ label: obj?.name, value: obj?.id, code: obj?.code }))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            validation={['required']}
            isClearable
            onChange={(branchItem) => {
                setValue('stockpoint', null);
                if (branchItem) {
                    setValue('branch', branchItem.value);
                }
            }}
        />
    )
}

const StockPoint = ({ name }) => {
    const { setValue, watch } = useFormContext();
    const branch = watch('branch');
    const { stockpointId } = useSelector(state => state?.homesidemenu?.profile);
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            ...currentParams,
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )
    useEffect(() => {
        if (branch && !stockpointId) {
            setParams(prev => ({
                ...prev,
                branchId: branch
            }));
        };
    }, [setParams, branch, stockpointId]);

    useEffect(() => {
        if (stockpointId) {
            setValue('stockpoint', stockpointId)
        }
    }, [stockpointId, setValue, stockPointList])

    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            validation={['required']}
            options={stockPointList?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
            placeholder="Pilih Stockpoint"
            isLoading={isLoadingStockPoint}
            isDisabled={true ?? (!!stockpointId || !branch)}
            isClearable={true}
            onChange={(ev) => {

            }}
        />
    )
}

export default App
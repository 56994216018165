import React from "react";
import Input from "components-form/Input";

const DEFAULT_MAX_MODAL = 99_999_999;
const FORMATTED_DEFAULT_MAX_MODAL = formatMoney(DEFAULT_MAX_MODAL);

const InputModal = (props) => (
    <Input
        {...props}
        type="text"
        placeholder="Maksimum Modal"
        validation={["required", "valueOfnull", `moreThanCurrent[${DEFAULT_MAX_MODAL}]`]}
        validationMessage={[`moreThanCurrent[Maksimum Modal tidak boleh melebihi ${FORMATTED_DEFAULT_MAX_MODAL}]`]}
        transform={{
            output: (e) => {
                const rawNumber = e.target.value.replace(/[^\d,]/g, "");

                return rawNumber.length
                    ? formatMoney(rawNumber)
                    : "";
            }
        }}
    />
);

function formatMoney(value) {
    return Intl.NumberFormat("id-ID").format(value);
}

export default InputModal;

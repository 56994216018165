import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input as ReactStrapInput, FormFeedback, InputGroup } from 'reactstrap'
import classNames from 'classnames';
// import { get } from 'lodash'
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const Input = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    invalidClass,
    ...props
}) => {
    const { control, getValues, setValue } = useFormContext()

    const transformer = { ...defaultTransform, ...transform }

    const handleInputChange = async (e) => {
        const fieldName = e.target.name;
        await setValue(fieldName, transformer.input(e.target.value), { shouldValidate: true });
    };
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({
                field: { ref, onChange, value, ...fieldInput },
                formState: { errors } }) => {
                return (
                    <InputGroup>
                        <ReactStrapInput
                            invalid={!!get(errors, name)}
                            innerRef={ref}
                            {...fieldInput}
                            maxLength={250}
                            onChange={e => {
                                handleInputChange(e)
                                onChange(transformer.output(e));
                            }}
                            value={transformer.input(value)}
                            {...props} />
                        {inputGroupAddon}
                        {get(errors, name) && <FormFeedback className={classNames(invalidClass)} invalid={"true"} style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </InputGroup>
                )
            }}
        />
    )
}

export default Input
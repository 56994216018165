import React, { useCallback, Fragment, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom'
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Spinner } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Switches from 'components-form/Switches';
import Checkbox, { RegularCheckbox } from './_components/Checkbox';
import { useAction, useFetch } from "hooks";
import { requestHomeSideMenu } from 'store/actions/homesidemenu';
import { getSelectableAccessRights, uncheckNonSelectableAccessRights } from './constant';

const getKey = (item) => item?.code;

// When editing authorization, this will ensure we're including up to date menu & actions
const mergeTemplate = (template, details, collectionName, overrideProperty) => template?.map((group) => {
    const groupId = getKey(group);
    const savedGroup = details?.find((d) => getKey(d) === groupId);

    if (!savedGroup) return group;

    return {
        ...group,
        [collectionName]: group?.[collectionName]?.map((item) => {
            const itemId = getKey(item);
            const savedItem = savedGroup[collectionName].find((s) => getKey(s) === itemId);

            if (!savedItem) return item;

            return {
                ...item,
                ...savedItem,
                ...(overrideProperty
                    ? overrideProperty(item, savedItem)
                    : {}),
            };
        })
    }
});

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const isCreate = params?.id ? false : true;
    const { hasFetch } = useAction();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const dataBreadcrumb = isCreate ?
        [
            { to: '/roles', label: 'Otorisasi Pengguna' },
            { to: null, label: 'Tambah Otorisasi' }
        ]
        : [
            { to: '/roles', label: 'Otorisasi' },
            { to: `/roles/${params.id}`, label: 'Detail Otorisasi' },
            { to: null, label: 'Edit Otorisasi' }
        ]

    /* fetching template permission */
    const { data: dataTemplateRole, status: statusTemplate } = useFetch(
        `/api/back-office/roles/template`,
        {},
        useCallback(data => ({ data: data?.data }), []),
        { onMount: true }
    );

    /* fecthing data detail */
    const { data, status, error } = useFetch(
        `/api/back-office/roles/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: params?.id }
    )

    const onSubmit = (input) => {
        if (isLoadingSubmit) return;
        setIsLoadingSubmit(true);

        /* request body */
        let requestBody = {
            name: input?.name,
            menuGroups: input?.menuGroups,
            actionGroups: input?.actionGroups,
            status: input?.status ? 'active' : 'inactive',
        };

        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/roles/create', requestBody)
            : Services().post(`/api/back-office/roles/edit`, { ...requestBody, id })

        action({ requestBody, id: params?.id })
            .then(res => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Otorisasi baru berhasil ditambah` : `Otorisasi berhasil diedit` }
                });
                hasFetch(requestHomeSideMenu());
                history.push(isCreate ? '/roles' : `/roles/${params?.id}`);
            }).catch(err => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }

    /* handle for Change Submit */
    const onChangeSubmit = (data) => {
        onSubmit(data)
    }
    /* Initialize default value form */
    let defaultValues = {
        name: data?.name ?? "",
        menuGroups: isCreate
            ? dataTemplateRole?.menuGroups
            : mergeTemplate(
                dataTemplateRole?.menuGroups,
                data?.menuGroups,
                "menus",
                (item, savedItem) => ({ accessRights: uncheckNonSelectableAccessRights(item.hasAccessRight, savedItem.accessRights) }),
            ),
        actionGroups: isCreate
            ? dataTemplateRole?.actionGroups
            : mergeTemplate(dataTemplateRole?.actionGroups, data?.actionGroups, "actions"),
        status: data?.status === 'active' || isCreate
    };

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return null;

    const dataHasLoaded = isCreate
        ? (statusTemplate === 'resolved')
        : (statusTemplate === 'resolved' && status === 'resolved');

    return dataHasLoaded && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Otorisasi</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="loginForm" onSubmit={onChangeSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Role
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="name" sm={3}>Nama Role</Label>
                                    <Col sm={9}>
                                        <Input validation={['required']} id="name" name="name" type="text" autoComplete="off" placeholder={'Nama Role'} maxLength={100} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label htmlFor={'status'} sm={3}>Status</Label>
                                    <Col sm={9}>
                                        <Switches
                                            className="custom-switch-success mt-1"
                                            id="status"
                                            name="status"
                                            label="Aktif"
                                            value={defaultValues.status}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {dataTemplateRole?.menuGroups?.map((item, index) => {
                            const isFirstLevelOnly = item?.url;

                            return (
                                <Fragment key={item?.code}>
                                    {index !== 0 && <hr className="mt-3 mb-4" />}
                                    {!isFirstLevelOnly && (
                                        <Row>
                                            <Col>
                                                <h5 className="mb-4">{item?.name}</h5>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            {item?.menus?.map((obj, objIndex) => {
                                                const prefix = `menuGroups.${index}.menus.${objIndex}.accessRights`;

                                                return (
                                                    <FormGroup row key={obj?.code} className="align-items-center">
                                                        {!isFirstLevelOnly
                                                            ? <Label sm={3} className="pb-0">{obj?.label}</Label>
                                                            : <Col sm={3} className="pb-0"><h5>{obj?.label}</h5></Col>}

                                                        <Col sm={9}>
                                                            <AuthorizationCheckbox
                                                                prefix={prefix}
                                                                hasAccessRight={obj?.hasAccessRight}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </Fragment>
                            );
                        })}

                        {dataTemplateRole?.actionGroups?.map((item, index) => {
                            const isFirstLevelOnly = item?.url;

                            return (
                                <Fragment key={`action-${index}`}>
                                    <hr className="mt-3 mb-4" />
                                    {!isFirstLevelOnly && (
                                        <Row>
                                            <Col>
                                                <h5 className="mb-4">{item?.name}</h5>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            {item?.actions?.map((obj, objIndex) => {
                                                const name = `actionGroups.${index}.actions.${objIndex}.allowed`;

                                                return (
                                                    <FormGroup row key={`subaction-${objIndex}`} className="align-items-center">
                                                        {!isFirstLevelOnly
                                                            ? <Label sm={3} htmlFor={obj?.code} className="pb-0">{obj?.label}</Label>
                                                            : <Col sm={3} className="pb-0"><h5>{obj?.label}</h5></Col>}

                                                        <Col sm={9}>
                                                            <Switches
                                                                id={name}
                                                                name={name}
                                                                label="Aktif"
                                                                className="custom-switch-success"
                                                                containerClass="align-items-center"
                                                                labelClass="ml-3"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </Fragment>
                            );
                        })}
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/roles" : `/roles/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" disabled={isLoadingSubmit}>
                                Batal
                            </Button>
                        </Link>
                        <SubmitButton isLoading={isLoadingSubmit}>
                            {isCreate ? "Buat" : "Simpan"}
                        </SubmitButton>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const SubmitButton = ({ isLoading, children }) => {
    const { formState: { isValid }, watch } = useFormContext();
    const menuGroups = watch("menuGroups");
    const hasSomeAccess = menuGroups?.some(({ menus }) => menus?.some(({ accessRights, hasAccessRight }) => {
        const selectables = getSelectableAccessRights(hasAccessRight);
        return selectables.some(({ value }) => accessRights[value] === true);
    }));

    const disabled = isLoading || !isValid || !hasSomeAccess;

    return (
        <Button type="submit" color="primary" className="m-1" disabled={disabled}>
            {isLoading && (
                <span className='mr-2'>
                    <Spinner size="sm" />
                </span>
            )}
            {children}
        </Button>
    );
};

const AuthorizationCheckbox = ({ prefix, hasAccessRight }) => {
    const { setValue, watch } = useFormContext();
    const canRead = watch(`${prefix}.read`);
    const selectables = useMemo(
        () => getSelectableAccessRights(hasAccessRight),
        [hasAccessRight]
    );

    const uncheckAllWhenReadGone = (event) => {
        if (event.target.checked) return;

        selectables.forEach(({ value }) => {
            if (value !== "read") {
                const name = `${prefix}.${value}`;
                setValue(name, false);
            }
        });
    };

    return (
        <div className="d-flex">
            <ToggleCheckAll
                prefix={prefix}
                options={selectables}
            />

            <div className="d-flex border-left pl-3">
                {selectables.map(({ label, value }) => {
                    const name = `${prefix}.${value}`;
                    const isRead = value === "read";
                    const disabled = !isRead && !canRead;

                    return (
                        <Checkbox
                            key={value}
                            id={name}
                            name={name}
                            label={label}
                            disabled={disabled}
                            containerClass={classNames("checkbox-pill", { "checkbox-inactive": disabled })}
                            onChange={isRead ? uncheckAllWhenReadGone : undefined}
                        />
                    );
                })}
            </div>
        </div>
    );
}

const ToggleCheckAll = ({ prefix, options }) => {
    const { setValue, watch } = useFormContext();
    const accessRights = watch(prefix);
    const checkedAll = options.length > 0 && options.every(({ value }) => accessRights?.[value] === true);

    const toggleCheckAll = (event) => {
        options.forEach(({ value }) => {
            const name = `${prefix}.${value}`;
            setValue(name, event.target.checked);
        });
    };

    return (
        <RegularCheckbox
            id={`${prefix}.all`}
            label="Pilih Semua"
            containerClass="checkbox-pill"
            checked={checkedAll}
            onChange={toggleCheckAll}
        />
    );
};

export default App
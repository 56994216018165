import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Collapse } from 'reactstrap';
import classNames from 'classnames';
import { format } from 'date-fns';
import ChevronMediumDownIcon from '@elevenia/edts-icon/glyph/ChevronMediumDown';

import { IdrFormat, parseValue } from 'helper';

import { indexPath } from '../constants';

const ValidationResultAccordion = ({ data }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="border bg-black-10" style={{ borderRadius: "8px" }}>
            <div
                onClick={toggle}
                className="d-flex align-items-center justify-content-between"
                style={{
                    padding: "12px 16px",
                    cursor: "pointer",
                }}
            >
                <h5 className="b2 text-black-70 mb-0" style={{ cursor: "inherit", fontWeight: 600 }}>
                    {parseValue(data?.stockpoint)}
                </h5>
                <span style={{ transition: "transform 0.3s ease", transform: `rotate(${isOpen ? "-180deg" : "0deg"})` }}>
                    <ChevronMediumDownIcon />
                </span>
            </div>
            <Collapse isOpen={isOpen}>
                <div style={{ padding: "0 16px 16px" }}>
                    <ValidList list={data?.validList} />
                    <InvalidList list={data?.invalidList} />
                </div>
            </Collapse>
        </div>
    );
};

const formatResultStatus = (status) => {
    switch (status) {
        case "valid":
            return <Badge color="success">Dapat Disetujui</Badge>;
        case "insufficient petty cash":
            return <Badge color="blue">Kas Tidak Cukup</Badge>;
        default:
            return <Badge color="danger">Gagal Validasi</Badge>;
    }
};

const ValidList = ({ list }) => {
    return (
        <>
            <hr className="mt-0" style={{ marginBottom: "12px" }} />
            <h6 className={classNames({ "mb-0": list?.length === 0 })}>
                Berhasil di submit : <span className="text-success">{parseValue(list?.length)}</span>
            </h6>
            <div className="d-flex flex-column" style={{ rowGap: "8px", marginBottom: "12px" }}>
                {list?.map((item, idx) => (
                    <div
                        key={item.reffNoChg}
                        className="border bg-white d-flex align-items-center justify-content-between"
                        style={{ borderRadius: "8px", padding: "12px" }}
                    >
                        <Link to={`${indexPath}/${item.id}`} target="_blank">
                            {idx + 1}. {item.reffNoChg}
                        </Link>
                        {formatResultStatus(item.validationInfo.status)}
                    </div>
                ))}
            </div>
        </>
    );
};

const formatBalance = (amount, fallbackValue) => parseValue(
    amount,
    (val) => <span className="text-primary" style={{ fontWeight: 600 }}>{IdrFormat(val)}</span>,
    fallbackValue
);

const InvalidTitle = ({ title }) => (
    <div className="mt-1" style={{ fontWeight: 600 }}>
        {title}
    </div>
);

const formatInvalidMessage = (item) => {
    switch (item.validationInfo.status) {
        case "insufficient petty cash":
            return (
                <>
                    <InvalidTitle title="Dana Kas Tidak Mencukupi" />
                    <div>Silahkan lakukan pencairan kas terlebih dahulu.</div>
                    <div>Kas Saat Ini : {formatBalance(item.validationInfo.balance)}</div>
                </>
            );
        case "insufficient modal balance":
            const purchaseDate = parseValue(
                item?.validationInfo?.date,
                (date) => format(new Date(date), 'dd/MM/yyyy - hh:mm')
            );

            return (
                <>
                    <InvalidTitle title="Modal Tidak Mencukupi" />
                    <div>Perubahan gagal karena modal anda tidak cukup.</div>
                    <div>Modal pada saat transaksi {purchaseDate} <br /> {formatBalance(item.validationInfo.balance)}</div>
                </>
            );
        case "insufficient modal before topup":
            return (
                <>
                    <InvalidTitle title="Modal Awal Tidak Cukup" />
                    <div>Modal anda sebelum Top Up terakhir tidak mencukupi.</div>
                    <div>Sisa Modal sebelum Top Up + jumlah total pembelian ini adalah {formatBalance(item.validationInfo.balance)}</div>
                    <div>Nominal yang bisa diubah tidak boleh melebihi jumlah di atas.</div>
                </>
            );
        default:
            return (
                <>
                    <InvalidTitle title="Unexpected Error" />
                    <div>Data perubahan tidak ditemukan atau sudah kedaluwarsa.</div>
                </>
            );
    }
};

const InvalidList = ({ list }) => {
    return (
        <>
            <hr className="mt-0" style={{ marginBottom: "12px" }} />
            <h6 className={classNames({ "mb-0": list?.length === 0 })}>
                Gagal di submit : <span className="text-danger">{parseValue(list?.length)}</span>
            </h6>
            <div className="d-flex flex-column" style={{ rowGap: "8px" }}>
                {list?.map((item, idx) => (
                    <div
                        key={item.reffNoChg}
                        className="border bg-white"
                        style={{ borderRadius: "8px", padding: "12px" }}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <Link to={`${indexPath}/${item.id}`} target="_blank">
                                {idx + 1}. {item.reffNoChg}
                            </Link>
                            {formatResultStatus(item.validationInfo.status)}
                        </div>
                        {formatInvalidMessage(item)}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ValidationResultAccordion;

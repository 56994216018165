import React, { useCallback, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { useAsyncPaginate, useComponents } from 'react-select-async-paginate';
import { Row, Col, Card, Badge, Input, Button } from "reactstrap";
import { addDays } from "date-fns";
import CancelIcon from "@elevenia/edts-icon/glyph/Cancel";
import CheckIcon from "@elevenia/edts-icon/glyph/Check";

import { Breadcrumb, Pagination, Table as TableGenerator } from "components";
import Form from "components-form/Form";
import Select from "components-form/Select";
import { capitalize, parseValue } from "helper";
import { useFetch } from "hooks";
import { Services } from "service";

import ModalBatchApproval, { useBatchApproval } from "./components/ModalBatchApproval";
import { editStatusOptions, formatDate, indexPath, xor } from "./constants";

const apiClient = Services();

const dataBreadcrumb = [
    { to: null, label: "Persetujuan Perubahan" }
]

export const statusOptions = editStatusOptions.map(({ label, value }) => ({ label, value }));

const formatStatus = (status) => {
    const editStatus = editStatusOptions.find(({ value }) => value === status);

    const {
        label = capitalize(status),
        badgeColor = "light",
    } = editStatus ?? {};

    return (
        <Badge color={badgeColor}>
            {label}
        </Badge>
    );
};

const App = ({ title: documentTitle, access: permissionAccess }) => {
    document.title = documentTitle;

    const { data, error, fetchData, loading, params, setParams, totalPage } = useFetch(
        "/api/back-office/direct-purchase/history",
        (currentParams) => ({
            size: 10,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback((data) => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    );

    const handleFilterChange = (name, value) => {
        setParams((prev) => ({
            ...prev,
            page: 1,
            [name]: value
        }));
    };

    const [currentDates, setCurrentDates] = useState([null, null]);

    const handleFilterDate = (dates) => {
        const [start, end] = dates;

        if (xor(start, end)) {
            setCurrentDates(dates);

            setParams((prev) => ({
                ...prev,
                page: 1,
                startDate: start ? formatDate(start, "dd-MM-yyyy") : null,
                endDate: end ? formatDate(end, "dd-MM-yyyy") : null
            }));
        }
    };

    const modalBatch = useBatchApproval();
    const [idLookUp, setIdLookUp] = useState({});
    const ids = useMemo(() => Object.keys(idLookUp).map(Number), [idLookUp]);

    const uncheckAll = () => setIdLookUp({});
    const isApprovalSelected = (data) => Boolean(idLookUp[data.id]);

    const handleSelectChange = (data, checked) => {
        setIdLookUp((prev) => {
            if (checked) {
                return {
                    ...prev,
                    [data.id]: data,
                };
            } else {
                const { [data.id]: unchecked, ...rest } = prev;
                return rest;
            }
        });
    };

    const invalidateCheckbox = () => {
        uncheckAll();
        fetchData();
    };

    const TableConfiguration = {
        columns: [
            permissionAccess?.update && {
                cellStyle: { position: "relative" },
                render: (_, row) => row?.status === "pending"
                    ? (
                        <Input
                            type="checkbox"
                            style={{ right: "50%", transform: "translateX(50%)" }}
                            checked={isApprovalSelected(row)}
                            onChange={(e) => handleSelectChange(row, e.target.checked)}
                        />
                    )
                    : null,
            },
            {
                key: "reffNoChg",
                title: "No Tiket Perubahan",
                render: (value, { id }) => parseValue(value, (ticketNo) => (
                    <Link to={`${indexPath}/${id}`}>
                        {ticketNo}
                    </Link>
                ))
            },
            {
                key: "branchName",
                title: "Branch",
                render: (value) => parseValue(value)
            },
            {
                key: "stockpointName",
                title: "Stockpoint",
                render: (value) => parseValue(value)
            },
            {
                key: "createdDate",
                title: "Tanggal Perubahan",
                render: (value) => parseValue(value, formatDate)
            },
            {
                key: "status",
                title: "Status",
                render: (value) => parseValue(value, formatStatus)
            },
        ].filter(Boolean)
    };

    return (
        <>
            <ModalBatchApproval config={modalBatch.config} onSucceed={invalidateCheckbox} />

            <Row className="mb-4 align-items-center" style={{ rowGap: "16px" }}>
                <Col md={4}>
                    <div className="d-inline-block">
                        <h2>Pembelian</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
                {ids.length > 0 && (
                    <Col>
                        <Row
                            className="flex-column flex-sm-row justify-content-between justify-content-md-end"
                            style={{ padding: "0 15px", columnGap: "10px", rowGap: "6px" }}
                        >
                            <Button color="link" className="mr-0 flex-grow-1 flex-md-grow-0 text-center py-2" onClick={uncheckAll}>
                                Batalkan
                            </Button>
                            <Button
                                outline
                                color="danger"
                                className="mr-0 flex-grow-1 flex-md-grow-0 d-flex align-items-center justify-content-center py-2"
                                onClick={() => modalBatch.show("reject", { ids, idLookUp })}
                            >
                                <CancelIcon color="#EE2B2E" size={20} />
                                <span className="ml-2">Tolak Semua</span>
                            </Button>
                            <Button
                                color="primary"
                                className="mr-0 flex-grow-1 flex-md-grow-0 d-flex align-items-center justify-content-center py-2"
                                onClick={() => modalBatch.show("accept", { ids, idLookUp })}
                            >
                                <CheckIcon color="#FFF" size={20} />
                                <span className="ml-2">Setujui Semua</span>
                            </Button>
                        </Row>
                    </Col>
                )}
            </Row>
            <Form>
                <Card body>
                    <Row style={{ rowGap: "16px" }}>
                        <Col md={5}>
                            <FilterDate
                                onChange={handleFilterDate}
                                previousValues={currentDates}
                            />
                        </Col>
                        <Col md={4}>
                            <FilterStockpoint
                                onChange={(selected) => handleFilterChange("stockpoint", selected?.value)}
                            />
                        </Col>
                        <Col md={3}>
                            <Select
                                isClearable
                                name="status"
                                placeholder="Pilih Status"
                                className="basic-single"
                                classNamePrefix="select"
                                options={statusOptions}
                                onChange={(selected) => handleFilterChange("status", selected?.value)}
                            />
                        </Col>
                    </Row>
                </Card >
            </Form>
            <Card body className="p-0">
                <TableGenerator
                    {...TableConfiguration}
                    dataList={data}
                    params={params}
                    loading={loading}
                    error={error}
                />
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        withSelector
                        showRowsPerPage={false}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                    />
                </Col>
            </Row>
        </>
    );
};

const FilterStockpoint = ({ onChange }) => {
    const loadOptions = async (search, _loadedOptions, additional) => {
        try {
            const { data: { data } } = await apiClient.get("/api/back-office/stockpoint", {
                search,
                page: additional.page,
                searchType: "name",
                size: 20,
            });

            return {
                hasMore: !data.last,
                options: data.content.map(({ id, name }) => ({ value: id, label: name })),
                additional: {
                    page: additional.page + 1,
                },
            };
        } catch (error) {
            return {
                options: [],
                additional,
                hasMore: false,
            };
        }
    };

    const components = useComponents();
    const asyncPaginateProps = useAsyncPaginate({
        loadOptions,
        debounceTimeout: 600,
        defaultOptions: true,
        additional: {
            page: 0,
        },
    });

    return (
        <Select
            {...asyncPaginateProps}
            components={components}
            isClearable
            name="stockpoint"
            placeholder="Pilih Stockpoint"
            className="basic-single"
            classNamePrefix="select"
            onChange={onChange}
        />
    );
};

const FilterDate = ({ previousValues, onChange }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const maxRange = 31;
    const maxDate = useMemo(
        () => {
            const today = new Date();
            if (!startDate) return today;

            const atMost = addDays(startDate, maxRange - 1);
            return atMost > today
                ? today
                : atMost;
        },
        [startDate]
    );

    return (
        <ReactDatePicker
            isClearable
            selectsRange
            placeholderText="Pilih Tanggal Perubahan"
            autoComplete="off"
            className="form-control"
            dateFormat="dd/MM/yyyy"
            maxDate={maxDate}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onCalendarClose={() => {
                if (xor(startDate, endDate)) return;

                const [start, end] = previousValues;
                setStartDate(start);
                setEndDate(end);
            }}
            onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);

                onChange(dates);
            }}
        />
    );
}

export default App;

import { mapObject } from "helper";

const ALL_ACCESS_RIGHTS = [
    { label: "Detail", value: "read" },
    { label: "Buat", value: "create" },
    { label: "Ubah", value: "update" },
    { label: "Hapus", value: "delete" },
];

const ALL_ACCESS_RIGHT_CODES = Object.fromEntries(
    ALL_ACCESS_RIGHTS.map(({ value }) => [value, true])
);

export const getSelectableAccessRights = (
    hasAccessRight = ALL_ACCESS_RIGHT_CODES,
    allOptions = ALL_ACCESS_RIGHTS,
) => {
    return allOptions.filter(({ value }) => hasAccessRight[value] === true);
};

export const uncheckNonSelectableAccessRights = (hasAccessRights, accessRights, allOptions = ALL_ACCESS_RIGHTS) => {
    const selectables = getSelectableAccessRights(hasAccessRights);

    return mapObject(accessRights, ([k, v]) => {
        const isAnAccessRightKey = allOptions.some(({ value }) => value === k);
        if (!isAnAccessRightKey) return [k, v];

        const isSelectable = selectables.some(({ value }) => value === k);
        return [
            k,
            isSelectable ? v : false,
        ];
    });
};

import React, { useEffect, useRef, useState } from 'react';
import { useContext } from "react";
import { Modalcontext } from "../detail";
import ModalPage from "components/Modal";
import { Button, Col, Label, Row } from "reactstrap";
import Form from 'components-form/Form';
import icCancel from 'assets/svg/ic_cancel.svg';
import { useFormContext } from 'react-hook-form';
import { Services } from 'service';
import { useAction } from 'hooks';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Spinner } from 'reactstrap';

const getJeriken = (data, volume) => data?.filter(item => item.type === "jeriken").find(item => item.volume === volume.toString());

const Penyelesaian = () => {
    const { resolved, setresolved, data } = useContext(Modalcontext);
    const jeriken18L = getJeriken(data?.oilDeliverySummary?.oilDeliveryDetails, 18);
    const jeriken20L = getJeriken(data?.oilDeliverySummary?.oilDeliveryDetails, 20);
    const defaultValue = {
        jeriken18L,
        jeriken20L,
        jumlahTitikJemput: data?.totalCompleteDelivery,
        noTiket: data?.tripPlanCode,
        warehouse: data?.warehouseName,
        oilCollected: 0,
        OilMissed: 0
    };
    const history = useHistory();
    const { hasFetch } = useAction();
    const [qr, setqr] = useState(null);
    const [imageurl, setimgurl] = useState(null);
    const refs = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => refs.current
    });
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const onSubmitted = (input) => {
        setIsLoadingSubmit(true);

        Services().post('/api/back-office/trip-plan/delivery-settlement', {
            tripCode: input?.noTiket,
            completedDelivery: input?.jumlahTitikJemput,
            oilContainerBeans: [
                input?.jeriken18L,
                input?.jeriken20L
            ],
            totalWeight: parseFloat(input?.oilCollected),
            notes: ""
        }, { responseType: "arraybuffer" })
            .then((result) => {
                setIsLoadingSubmit(false);
                const blob = new Blob([result?.data], { type: "image/jpg" });
                const uris = URL.createObjectURL(blob);
                setimgurl(uris)
                setqr(!qr)
            })
            .catch(err => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    };

    return (
        <ModalPage
            isOpen={resolved}
            handleClose={() => setresolved(!resolved)}
            component={(
                <>
                    {qr && <div className='text-right'>
                        <span onClick={() => {
                            history.go(0)
                            setresolved(!resolved)
                        }} style={{ cursor: "pointer" }}><img src={icCancel} alt="pict-cancel" /></span>
                    </div>}
                    {!qr && <div className='moda-password-title font-weight-bold'>Konfirmasi Penyelesaian</div>}
                    {qr && <Row className='justify-content-center align-items-center flex-column'>
                        <div className='moda-password-title align-items-center m-0'>Qr Code Penugasan</div>
                        <img id='print-out' ref={refs} style={{ maxWidth: "100%", width: 250 }} src={imageurl} alt="pict-qr" />
                        <Button
                            onClick={handlePrint}
                            type="button" outline color='primary'>
                            <i className='fa fa-print mr-2'></i>
                            Print Rute Jemput
                        </Button>
                    </Row>}
                    {!qr &&
                        <Form onSubmit={onSubmitted} id="penggunaForm" autoComplete="off" defaultValues={defaultValue}>
                            <KonfirmasiPenyelesaian
                                isLoadingSubmit={isLoadingSubmit}
                                onDismiss={() => setresolved(false)}
                            />
                        </Form>}
                </>
            )}
        />
    )
};

const KonfirmasiPenyelesaian = ({
    isLoadingSubmit,
    onDismiss
}) => {
    const { data } = useContext(Modalcontext);
    const [dataConfirm, setDataConfirm] = useState('');
    const [loading, setLoading] = useState(false);
    const { watch, setValue } = useFormContext();

    const jeriken18L = watch('jeriken18L');
    const jeriken20L = watch('jeriken20L');

    useEffect(() => {
        const requestBody = {
            "id": data?.id,
            "oilContainerBeans": [
                jeriken18L,
                jeriken20L
            ]
        };

        Services().post('/api/back-office/trip-plan/delivery/confirm', requestBody)
            .then(({ data }) => {
                setLoading(true);
                setDataConfirm(data?.data);
                setValue("oilCollected", data?.data?.totalWeight);
            });
    }, [data, jeriken18L, jeriken20L, setValue]);

    const IdrFormat = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number);

    if (!loading) return <div className='d-flex justify-content-center'><Spinner size="lg" color="primary" /></div>;
    return (
        <div>
            <div style={{ gap: "1rem" }} className='d-flex flex-column'>
                <div>
                    <div className='d-flex flex-column'>
                        <Label style={{ color: "#70727D" }}>No Tiket</Label>
                        <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.tripCode}</span>
                    </div>
                </div>
                <Row>
                    <Col md={7}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Warehouse</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.destinationName}</span>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Titik Jemput</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.completedPickup}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={7}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Jeriken 18 L</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{getJeriken(dataConfirm?.settlementContainerBeans, 18)?.qty}</span>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Jeriken 20 L</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{getJeriken(dataConfirm?.settlementContainerBeans, 20)?.qty}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={7}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Jumlah Minyak Diangkut</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.totalWeight} Kg</span>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Harga per Kg</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{IdrFormat(dataConfirm?.averagePrice)}</span>
                        </div>
                    </Col>
                </Row>
                <div className='d-flex justify-content-end pt-4'>
                    <Button onClick={onDismiss} type="button" size='sm' outline color="primary">Kembali</Button>
                    <Button size='sm' color="primary" disabled={isLoadingSubmit}>
                        {isLoadingSubmit &&
                            <span className='mr-2'>
                                <Spinner size="sm" />
                            </span>}
                        Konfirmasi
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Penyelesaian;

import React from "react";
import { Button, Col, Row } from "reactstrap";
import CancelIcon from "@elevenia/edts-icon/glyph/Cancel";
import CheckIcon from "@elevenia/edts-icon/glyph/Check";

import ModalConfirmApproval, { useConfirmApproval } from "./ModalConfirmApproval";

const ButtonApproval = ({
    historyId,
    purchaseDate,
    refetchDetail,
}) => {
    const modal = useConfirmApproval();

    return (
        <>
            <ModalConfirmApproval
                config={modal.config}
                refetchDetail={refetchDetail}
            />

            <Row className="pb-1">
                <Col className="d-flex flex-row justify-content-end">
                    <IconButton
                        outline
                        color="danger"
                        label="Tolak"
                        icon={<CancelIcon color="#EE2B2E" size={18} />}
                        onClick={() => modal.confirm("reject", { historyId })}
                    />
                    <IconButton
                        color="primary"
                        label="Setujui"
                        icon={<CheckIcon color="#FFF" size={18} />}
                        style={{ paddingLeft: "10px", paddingRight: "14px" }}
                        onClick={() => modal.confirm("accept", { historyId, purchaseDate })}
                    />
                </Col>
            </Row>
        </>
    );
};

const IconButton = ({
    icon,
    label,
    style = {},
    ...props
}) => (
    <Button
        color="primary"
        className="py-2 d-inline-flex align-items-center"
        style={{ gap: "4px", ...style }}
        {...props}
    >
        {icon}
        <span>{label}</span>
    </Button>
);

export default ButtonApproval
import React, { useCallback, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import classNames from "classnames";

import SmallButton from "components/SmallButton";
import { parseValue } from "helper";
import { showToast } from "hooks";
import { Services } from "service";

import ValidationResultAccordion from "./ValidationResultAccordion";

const apiClient = Services();

export const useBatchApproval = () => {
    const config = useState(null);
    const [, setState] = config;

    const show = (mode, payload) => setState({ mode, payload, isOpen: Boolean(mode) });

    return {
        config,
        show
    };
};

const approvalActions = [
    { code: "accept", verb: "dilakukan", noun: "Persetujuan" },
    { code: "reject", verb: "ditolak", noun: "Tolak" },
];

const toastMessage = (verb, status = "berhasil") => `Permohonan perubahan ${status} ${verb}`;

const ModalBatchApproval = ({ config, onSucceed }) => {
    const [state, setState] = config;
    const [submitting, setSubmitting] = useState(false);

    const accepting = state?.mode === "accept";
    const rejecting = state?.mode === "reject";
    const isValidated = state?.payload?.isValidated === true;

    const close = () => {
        setState((prev) => ({ ...prev, isOpen: false }));
    };

    const clearState = () => {
        setState(null);
    };

    const submit = (action, autoConfirm = false) => {
        const knownAction = approvalActions.find(({ code }) => code === action);

        // Prevent duplicated or invalid request
        if (submitting || !knownAction || !state?.payload?.ids?.length) return;

        const { ids } = state.payload;

        if (isValidated) {
            confirmAction(knownAction, state.payload?.confirmPayload);
        } else {
            validateAction(knownAction, ids, autoConfirm);
        }
    };

    const clasifyInvalidStatus = (failDetail, ticketDetail) => {
        const [status, balance] = parseValue(
            failDetail?.message,
            (message) => message.split(":::"),
            [],
        );

        return {
            ...ticketDetail,
            validationInfo: {
                status,
                balance,
                date: failDetail?.date,
            },
        };
    };

    const validateAction = (knownAction, ids, autoConfirm) => {
        setSubmitting(true);

        apiClient.post(`/api/back-office/direct-purchase/history/bulk/validation/${knownAction.code}`, { ids })
            .then((res) => {
                const data = res?.data?.data;
                const confirmPayload = {
                    action: data?.action,
                    successList: data?.successList,
                    v2Fail: data?.v2Fail,
                    otherFail: data?.otherFail,
                }

                /**
                 * Allow to skip showing validation results breakdown
                 * when they're all passed.
                 */
                if (autoConfirm && data.failList.length === 0) {
                    confirmAction(knownAction, confirmPayload);
                    return;
                }

                const groupByStockpoint = ids.reduce(
                    (acc, id) => {
                        const ticketDetail = state.payload.idLookUp[id];
                        const stockpoint = ticketDetail.stockpointName;
                        const currentGroup = acc[stockpoint];
                        const isValid = data.successList.includes(id);

                        const validationResult = isValid
                            ? {
                                ...ticketDetail,
                                validationInfo: {
                                    status: "valid",
                                },
                            }
                            : clasifyInvalidStatus(
                                data.failList.find(({ id }) => id === ticketDetail.id),
                                ticketDetail,
                            );

                        return {
                            ...acc,
                            [stockpoint]: currentGroup
                                ? {
                                    ...currentGroup,
                                    validList: isValid
                                        ? currentGroup.validList.concat([validationResult])
                                        : currentGroup.validList,
                                    invalidList: isValid
                                        ? currentGroup.invalidList
                                        : currentGroup.invalidList.concat([validationResult]),
                                }
                                : {
                                    stockpoint,
                                    validList: isValid ? [validationResult] : [],
                                    invalidList: isValid ? [] : [validationResult],
                                },
                        };
                    },
                    {}
                );

                const validationResults = Object
                    .values(groupByStockpoint)
                    .toSorted((a, b) => a.stockpoint.localeCompare(b.stockpoint));

                setState((prev) => ({
                    ...prev,
                    payload: {
                        ...prev.payload,
                        isValidated: true,
                        noun: knownAction.noun,
                        validationResults,
                        confirmPayload,
                    },
                }));

                setSubmitting(false);
            })
            .catch((e) => {
                showToast({ name: "error", message: e?.message ?? toastMessage("divalidasi", "gagal") });
                setSubmitting(false);
            });
    };

    const confirmAction = (knownAction, payload) => {
        setSubmitting(true);

        apiClient.put("/api/back-office/direct-purchase/history/bulk/confirm", payload)
            .then(() => {
                showToast({ name: "success", message: toastMessage(knownAction.verb) });
                close();
                onSucceed();
            })
            .catch((e) => {
                showToast({ name: "error", message: e?.message ?? toastMessage(knownAction.verb, "gagal") })
            })
            .finally(() => {
                setSubmitting(false);
            })
    };

    const [hasScrollBar, setHasScrollBar] = useState(false);
    const showBorder = isValidated && hasScrollBar;

    const detectScrollBar = useCallback(
        (node) => {
            const resizeObserver = new ResizeObserver(([entry]) => {
                const { clientHeight, scrollHeight } = entry.target;
                setHasScrollBar(scrollHeight > clientHeight);
            });

            if (node !== null) {
                const modalBody = node.querySelector(".modal-body");
                resizeObserver.observe(modalBody);
            } else {
                resizeObserver.disconnect();
            }
        },
        []
    );

    return (
        <Modal
            centered
            size={isValidated ? "md" : "sm"}
            className={classNames({ "enlarge-md": isValidated })}
            backdrop="static"
            keyboard={!submitting}
            returnFocusAfterClose={false}
            isOpen={state?.isOpen}
            toggle={close}
            onClosed={clearState}
            innerRef={detectScrollBar}
        >
            <ModalHeader
                className={classNames({ "border-bottom": showBorder })}
                style={{ paddingTop: "12px", paddingBottom: "12px" }}
            >
                <span className="b1" style={{ fontWeight: 600 }}>
                    {isValidated
                        ? `Detail Hasil ${state.payload.noun} Masal`
                        : accepting
                            ? "Persetujuan Perubahan"
                            : rejecting
                                ? "Tolak Permohonan"
                                : null}
                </span>
            </ModalHeader>
            <ModalBody
                style={{
                    overflowY: "auto",
                    maxHeight: "70vh",
                    paddingTop: showBorder ? undefined : "4px",
                    paddingBottom: showBorder ? undefined : "4px",
                    opacity: submitting && isValidated ? 0.5 : undefined,
                    pointerEvents: submitting && isValidated ? "none" : undefined,
                }}
            >
                <div>
                    {isValidated
                        ? (
                            <div className="d-flex flex-column" style={{ rowGap: "16px" }}>
                                {state.payload.validationResults.map((result, idx) => (
                                    <ValidationResultAccordion
                                        key={`${idx}-${result.stockpoint}`}
                                        data={result}
                                    />
                                ))}
                            </div>
                        )
                        : accepting
                            ? <><b className="text-primary">{state.payload.ids.length}</b> Data Pembelian Langsung akan diubah</>
                            : rejecting
                                ? <><b className="text-primary">{state.payload.ids.length}</b> Permohonan perubahan akan ditolak</>
                                : null}
                </div>
            </ModalBody>
            <ModalFooter className={classNames({ "border-top": showBorder })}>
                {(accepting || rejecting || isValidated)
                    ? (
                        <>
                            <SmallButton
                                block={isValidated}
                                disabled={submitting}
                                outline={!submitting}
                                color={submitting ? "secondary" : "primary"}
                                className={classNames({ "mt-0": isValidated })}
                                onClick={close}
                            >
                                Kembali
                            </SmallButton>
                            <SmallButton
                                block={isValidated}
                                color="primary"
                                disabled={submitting}
                                className={classNames({ "position-relative": submitting }, { "mt-0": isValidated })}
                                onClick={(e) => {
                                    e.target.blur();
                                    const autoConfirm = rejecting;
                                    submit(state.mode, autoConfirm);
                                }}
                            >
                                {submitting && <Spinner size="sm" className="position-absolute" style={{ top: "25%", left: isValidated ? "45%" : "42%" }} />}
                                <span className={submitting ? "invisible" : ""}>
                                    {(accepting && !isValidated) ? "Tinjau Perubahan" : "Konfirmasi"}
                                </span>
                            </SmallButton>
                        </>
                    )
                    : null}
            </ModalFooter>
        </Modal>
    );
};

export default ModalBatchApproval;
